import React from 'react'
import 'scroll-behavior-polyfill'
import styled from 'styled-components'
import { Seo } from './Seo'

type LayoutProps = {
    isModalVisible?: boolean
}

export const Layout: React.FunctionComponent<LayoutProps> = ({
    children,
    isModalVisible
}) => (
    <Container isModalVisible={isModalVisible}>
        <Seo/>
        {children}
    </Container>
)

const Container = styled.div<LayoutProps>`
    width: 100%;
    position: relative;
    height: 100vh;
    overflow: ${props => props.isModalVisible ? 'hidden' : 'auto'};
`
