import styled from 'styled-components'
import { colors, MediaBreakpoints } from 'lib/styles'

export const H3 = styled.h3`
    color: ${colors.blue};
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    @media (max-width: ${MediaBreakpoints.SM}px) {
        font-size: 28px;
    }
`
